@import "./variable.scss";
.main-header {
  background-color: #fff;
  border-bottom: 1px solid $cuColorGray4;
  padding: 8px 0;
  .main-header-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-price-body {
      display: flex;
      align-items: center;
      gap: 16px;
      .common-header-text {
        font-size: 12.5px;
        color: $cuColorGray1;
        .price,
        .measure {
          color: $primary;
        }
        .rate {
          color: $darkGreen;
        }
      }
    }
    .search-body {
      width: 100%;
      position: relative;
      @media screen and (min-width: 768px) {
        max-width: 580px;
      }
      .search-icon {
        position: absolute;
        left: 14px;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        svg {
          path {
            fill: $cuColorGray3;
          }
        }
      }
      .dash-icon {
        position: absolute;
        top: 0;
        right: 14px;
        bottom: 0;
        @include all_center();
        background-color: #081d3540;
        color: $white;
        border-radius: 6px;
        padding: 2px 8px;
        height: 23px;
        margin: auto;
      }
      input {
        padding: 6px 80px 6px 38px;
        background-color: $cuColorGray2 !important;
        &::placeholder {
          color: $cuColorGray1;
          opacity: 1;
        }
        &:focus {
          background-color: $white !important;
          box-shadow: none;
        }
      }
    }
  }
}
