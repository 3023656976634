@import "../scss/variable.scss";

.main-navbar {
  color: $black;
  border-bottom: 1px solid $cuColorGray4;

  .main-navbar-body {
    .navbar-brand {
      margin-right: auto;
      .logo-part {
        svg {
          width: 150px;
          height: 36px;
        }
      }
    }

    .navbar {
      padding: 0;
      .container-fluid {
        padding: 0 !important;
        @media screen and (max-width: 992px) {
          justify-content: unset;
        }
      }
      .main-navbar-toggler {
        @media screen and (max-width: 992px) {
          // order: 1;
        }
        &:focus {
          box-shadow: 0 0 0 0.15rem;
        }
      }
    }
  }
}
.navbar-nav {
  .nav-item {
    .nav-link {
      color: $black;
      &.home-link {
        padding: 16px 12px !important;
      }
      padding: 16px 26px 16px 12px !important;
      font-size: 14.5px;
      &:hover {
        color: $primary;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
.navbar-text {
  padding: 16px 12px !important;
  color: $black;
}
.cu-dropdown-toggle {
  position: relative;
  .dropdown-icon {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    svg {
      margin-top: -2px;
      width: 12px;
      height: 12px;
    }
  }
}
.custom-dropdown {
  .dropdown-menu {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 3px solid $primary;
    animation: fadeInUp 0.2s ease;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    @media screen and (max-width: 992px) {
      box-shadow: none;
      border: 1px solid $cuColorGray4;
      border-radius: 8px;
    }
    padding: 8px;
    .dropdown-item {
      font-size: 13px;
      color: $cuColorBlack1;
      padding: 6px 12px;
      .dropdown-menu-sub-text {
        color: $cuColorGray1;
        font-size: 11px;
      }
      &:hover {
        background-color: $cuColorGray4;
        border-radius: 6px;
      }
    }
    .dropdown-divider {
      border-color: $cuColorGray4;
    }
  }
}
