@import "../../assests/scss/variable.scss";
.page-not-found {
  background-image: url("../../assests/images/error-404.svg");
  background-repeat: no-repeat, repeat;
  height: 410px;
  background-size: cover;
  background-position: center center;
  .main-body {
    .text-body {
      padding-top: 32px;
      h1,
      p {
        color: $cuColorGray1 !important;
      }
      h1 {
        margin: 20px 0;
        font-size: 29px;
        line-height: 1.2;
      }
      p {
        font-size: 14.5px;
      }
    }
    .back-button{
    }
  }
}
