@import "./variable.scss";

.footer-body {
  background-color: $cuColorGray2;
  width: 100%;
  a {
    text-decoration: none;
    color: inherit;
  }
  .main-footer-menu {
    border-top: 1px solid $cuColorGray4;
    border-bottom: 1px solid $cuColorGray4;
    padding: 36px 0 18px 0;
    .footer-title-body {
      margin-bottom: 12px;
      .footer-title {
        font-size: 18.7px;
      }
    }
    .footer-text {
      font-size: 12.5px;
      margin-bottom: 10px;
    }
    .common-footer-menu {
      h4 {
        margin-bottom: 12px;
        font-size: 14.5px;
      }
      ul li {
        margin-bottom: 12px;
        line-height: 18.5px;

        a {
          font-size: 12.5px;
          line-height: 18.5px;
          color: $cuColorBlack2 !important;
        }
      }
    }
  }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12.5px;
    color: $cuColorBlack1;
    padding: 16px 0;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
    }
    p {
      margin-bottom: 0;
    }
  }
  .social-footer {
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .social-footer-link {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon-body {
        @include all_center();
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: $cuColorGray4;
      }
    }
    .back-to-top {
      color: $cuColorBlack2;
      font-size: 14.5px;
      @include all_center();
      .combine-icon {
        position: relative;
        .dash-icon {
          position: absolute;
          top: -15px;
          left: -2px;
        }
      }
    }
  }
}
