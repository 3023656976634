@import "../../assests/scss/variable.scss";
.block-body {
  background-image: linear-gradient(#f8fbfd 0, rgba(246, 247, 248, 0) 85rem);
  width: 100%;
  padding-bottom: 48px;
  .card {
    box-shadow: 0 0.5rem 1.2rem rgb(189 197 209/20%);
  }
  .block-header {
    .block-main-header {
      padding: 20px 0;
      border-bottom: 1px solid $cuColorGray4;
      .header-body {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        h1 {
          color: $cuColorBlack1;
          font-size: 19px;
        }
        p {
          color: $cuColorGray1;
          font-size: 14.5px;
        }
      }

      .block-sub-header-chip {
        .sub-chip {
          @include all_center();
          color: $black;
          background-color: $white;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 50rem;
          padding: 6px 8px;
          width: fit-content;
          gap: 4px;
          font-size: 11px;
        }
      }
    }
    .block-sub-text {
      a {
        text-decoration: none;
        color: inherit;
      }
      padding: 16px 0;
      color: $cuColorGray1;
      font-size: 14.5;
    }
  }
  .block-tabs {
    .tab-content {
      .tab-pane {
        .common-tab-body {
          .block-height {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
  }
}
