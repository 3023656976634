@import "../../assests//scss/variable.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.transaction-body {
  background-image: linear-gradient(#f8fbfd 0, rgba(246, 247, 248, 0) 85rem);
  width: 100%;
  padding-bottom: 48px;
  .card {
    box-shadow: 0 0.5rem 1.2rem rgb(189 197 209/20%);
  }
  .transaction-all-tab {
    .nav-overview-tab {
      .nav {
        margin-bottom: 12px;
      }
      .transfer-tab {
        a {
          line-height: 1;
        }
      }
    }
    .textarea-input {
      box-shadow: none;
      font-size: 14.5px;
      font-family: "Roboto Mono", monospace;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;

      &:active {
        box-shadow: 0 0 0 0.15rem rgba(0, 0, 0, 0.1);
        border-color: rgba(255, 255, 255, 0.5);
      }
      background-color: rgb(248, 249, 250);
      color: rgb(108, 117, 125);
      &::-webkit-scrollbar {
        width: 8px;
        background-color: rgb(248, 249, 250);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: $cuColorGray4;
      }
    }
    .logs-tab {
      .logs-header {
        font-size: 14.5px;
        margin-bottom: 16px;
        color: $cuColorBlack1;
      }
      .logs-body {
        @media screen and (max-width: 576px) {
          // gap: 0px !important;
        }
        .number-body {
          min-width: 2.8rem;
          min-height: 2.8rem;
          @include all_center();
          border-radius: 50%;
          background-color: rgba(0, 161, 1340, 0.1);
          .number {
            color: $primary;
            font-size: 10px;
          }
        }
        .right-log-body {
          @media screen and (min-width: 992px) {
            width: 85%;
          }
          width: 100%;
        }
        .log-info-body {
          margin-bottom: 12px;
          @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: start !important;
          }
          &.log-name-info {
            font-size: 14.5px;
            color: $cuColorGray1;
            padding-left: 1px;
          }
          &.log-other-info {
            font-size: 10.5px;
            color: $cuColorBlack1;
          }
          .log-info-item {
            min-width: 82px;
            padding: 0 12px;
            font-size: 14.5px;
            @media screen and (max-width: 768px) {
              text-align: start !important;
            }
            &.cu-secondary-text {
              color: $cuColorGray1;
            }
          }
          .log-info-desc {
            padding: 0 12px;
            &.log-info-data-body {
              background-color: $cuColorGray2;
              border-radius: 8px;
            }
            .cu-body-text-danger {
              color: $cuDanger1;
            }
            .cu-body-text-success {
              color: $darkGreen;
            }
            .log-info-nav-body {
              .nav {
                .nav-item {
                  .nav-link {
                    &.dec-body {
                      border-top-right-radius: 0 !important;
                      border-bottom-right-radius: 0 !important;
                    }
                    &.hex-body {
                      border-top-left-radius: 0 !important;
                      border-bottom-left-radius: 0 !important;
                    }
                    color: $cuColorBlack1;

                    background-color: rgb(236, 238, 239);
                    &.active {
                      background-color: #bdc4ca !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .state-tab-body {
      .common-state-chip {
        background-color: #adb5bd1a;
        padding: 6px 8px;
        border: 1px solid $cuColorGray4;
        border-radius: 6px;
        width: fit-content;
        p {
          color: $black;
          font-size: 10.8px;
          font-weight: 500;
        }
      }
      .state-desc-body {
        padding: 0 8px 12px;
        gap: 8px;

        .desc-text {
          font-size: 14.5px;
          color: $black;
        }
      }
      .state-table-body {
        .rdt_Table {
          .rdt_TableHead {
            .rdt_TableHeadRow {
              .rdt_TableCol {
                font-size: 12.5px;
                color: $cuColorBlack1;
                font-weight: 700;
              }
            }
          }
          .rdt_TableRow {
            padding: 9px 0;
            .rdt_TableCell {
              font-size: 14.5px;
              color: $cuColorBlack1;
            }
          }
        }
      }
    }
  }
  .transaction-header {
    .transaction-header-body {
      padding: 16px 0;
      border-bottom: 1px solid $cuColorGray4;
      h1 {
        color: $cuColorBlack1;
        font-size: 19px;
      }
    }
    .transaction-sub-text {
      a {
        text-decoration: none;
        color: inherit;
      }
      padding: 16px 0;
      color: $cuColorGray1;
      font-size: 14.5;
    }
  }
}
