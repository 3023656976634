@import "../../assests//scss/variable.scss";
.address-body {
  a {
    text-decoration: none;
    color: inherit;
  }
  background-image: linear-gradient(#f8fbfd 0, rgba(246, 247, 248, 0) 85rem);
  width: 100%;
  padding-bottom: 48px;
  .card {
    box-shadow: 0 0.5rem 1.2rem rgb(189 197 209/20%);
  }
  .address-header {
    .address-header-body {
      padding: 16px 0;
      border-bottom: 1px solid $cuColorGray4;
      h1 {
        color: $cuColorBlack1;
        font-size: 19px;
      }
      p {
        font-size: 15px;
      }
    }
    .address-sub-text {
      padding: 16px 0;
      color: $cuColorGray1;
      font-size: 14.5;
    }
  }
  .address-chip-body {
    margin-top: 16px;
    margin-bottom: 8px;
    .common-address-chip {
      border-radius: 50rem;
      font-size: 10.8px;
      padding: 4px 8px;

      width: fit-content;
      &.cu-secondary-chip {
        background-color: $cuColorGray9;
        color: $black;
        border: 1px solid $cuColorBlack3;
      }
      &.cu-normal-chip {
        background-color: $white;
        color: $black;
        border: 1px solid $cuColorBlack3;
      }
      &.cu-success-chip {
        background-color: $darkGreen1;
        color: $darkGreen;
        border: 1px solid $cuColorGray4;
      }
    }
  }
  .address-card-info-body {
    .custom-info-card {
      padding: 16px;
      box-lines: 0 0.5rem 1.2rem rgb(189 197 209 /20%);
      border: 1px soild $cuColorGray4;
      border-radius: 12px;
      background-color: $white;
      .card-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        h2 {
          font-size: 14.5px;
          color: $cuColorBlack1;
          font-weight: 500;
        }
        &.multi-chain-body {
          gap: 0;
        }
        .each-info {
          .card-info-title {
            margin-bottom: 4px;
            font-size: 12px;
            color: $cuColorGray1;
            text-transform: uppercase;
          }
          .card-info-value {
            font-size: 14.5px;
            color: $cuColorBlack1;
            font-weight: 500;
          }
          .card-sub-info-value {
            font-size: 12.5px;
          }
          .card-sub-seconday-info-value {
            font-size: 12.5px;
            color: $cuColorGray1;
          }
          .holding-body {
            gap: 8px;
            .holding-value {
              padding: 8px 12px;
              border: 1px solid $cuColorGray4;
              border-radius: 8px;
              width: 100%;
            }
            .wallet-body {
              padding: 8px 12px;
              background-color: $cuColorGray4;
              border-radius: 8px;
            }
          }
          .add-body {
            padding: 4px 16px;
            border-radius: 50rem;
            border: 0.8px dotted rgba(0, 0, 0, 0.15);
            width: fit-content;
          }
          .sent-body {
            font-size: 14.5px;
            .title {
              color: $cuColorGray1;
            }
            .value {
              color: $cuColorBlack2;
            }
            svg {
              rotate: -41deg;
            }
          }
          .funded-body {
            font-size: 14.5px;
            color: $cuColorGray1;
          }
          .multichain-price-body {
            background-color: $cuColorGray4;
            color: $cuColorBlack2;
            font-size: 12.5px;
            padding: 4px 8px;
            border-radius: 4px;
            width: fit-content;
            margin-bottom: 12px;
            margin-top: 8px;
            .value {
              font-weight: 500;
            }
          }
          .multichain-address-body {
            font-size: 14.5px;
            color: $cuColorBlack1;
          }
        }
      }
    }
  }
  .star-icon-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 6px;
    background-color: $white;
    border: 1px solid $cuColorGray4;
  }
  .address-table {
    .rdt_Table {
      .rdt_TableHead {
        .rdt_TableHeadRow {
          .rdt_TableCol {
            font-size: 12.5px;
            color: $cuColorBlack1;
            font-weight: 700;
          }
        }
      }
      .rdt_TableRow {
        padding: 9px 0;
        .rdt_TableCell {
          font-size: 14.5px;
          color: $cuColorGray1;

          .eye-body {
            background-color: $white;
            width: 28px;
            height: 28px;
            border: 1px solid $cuColorGray4;
            border-radius: 6px;
            @include all_center();
          }
          .method-transfer-body {
            background-color: $cuColorGray2;
            border-radius: 6px;
            color: $black;
            padding: 6px 8px;
            font-size: 11px;
            font-style: italic;
            border: 1px solid #0000001a;
            @include all_center();
            width: 75px;
            &:hover {
              background-color: #ffc10726;
              border: 1px dashed $cuWarning1;
            }
          }
          .common-mode {
            @include all_center();
            padding: 6px 8px;
            background-color: #ffc10726;
            color: #cc9a06;
            border: 1px solid #cc9a06;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 700;
            border-radius: 6px;
            &.mode-in {
              background-color: rgba(0, 161, 134, 0.1);
              color: #00a186;
              border: 1px solid #00a186;
            }
          }
          .row-tx-fee {
            font-size: 12.7px;
            color: $cuColorGray1;
          }
        }
      }
    }
  }
  .address-no-data-body {
    text-align: center;
    font-size: 14.5px;
    color: $cuColorBlack1;
  }
}
