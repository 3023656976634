@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$primary: #0784c3;
$cuprimary1: #0784c31a;
$danger: #e64646;
$cuDanger1: #dc3545;
$warning: #ffd700;
$cuWarning1: #ffc107;
$black: #000000;
$offWhite: #ebffff;
$darkGreen: #00a186;
$darkGreen1: #00a1861a;
$white: #ffffff;
$cuColorGray1: #6c757d;
$cuColorGray2: #f8f9fa;
$cuColorGray3: #adb5bc;
$cuColorGray4: #e9ecef;
$cuColorGray5: #fafbfd;
$cuColorGray6: #dee2e6;
$cuColorGray7: #adb5bd;
$cuColorGray8: #adb5bd1a;
$cuColorGray9: #adb5bd26;
$cuColorBlack1: #212529;
$cuColorBlack2: #081d35;
$cuColorBlack3: #00000026;
$gloabal_width: 1190px;
$primaryFont: "Roboto", sans-serif;

// mediaSizes
$mediaSizes: (
  "xs": 320px,
  "sm": 576px,
  "mid": 700px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1500px,
);

@mixin all_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
