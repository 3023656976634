@import "./assests/scss/variable.scss";

*,
body {
  padding: 0;
  margin: 0;
  font-family: $primaryFont;
  @media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1378px;
    }
  }
}
.cu-text-primary {
  color: $primary !important;
}
.cu-text-dark {
  color: $cuColorBlack1 !important;
}

.cu-font-12-5 {
  font-size: 12.5px;
  .common-link-text {
    font-size: 12.5px;
  }
}

.cu-fw-500 {
  font-weight: 500;
}

.btn-primary {
  background-color: $primary !important ;
  border-color: $primary !important ;
}

.info-icon-body {
  border-radius: 50%;
  border: 1px solid $cuColorGray1;
  @include all_center();
  width: 14px;
  height: 14.5px;
  margin-top: -1.6px;
  &.address-info-icon {
    width: 12px;
    height: 12px;
    margin: 0;
    border: 1px solid $cuColorBlack1;
    svg {
      width: 4px;
      path {
        fill: #212529;
      }
    }
  }
}

.cu-common-info-header {
  font-size: 14.5px;
  line-height: 1;
  .label {
    color: $cuColorGray1;
    @media screen and (max-width: 768px) {
      color: $cuColorBlack2;
      font-weight: 500;
    }
  }
}
.sign-in-link-color-cursor {
  cursor: pointer;
  font-size: 14.5px;
  &:hover {
    color: $primary;
  }
}
.common-link-text {
  font-size: 14.5px;
  color: $primary !important;
  cursor: pointer;
  &.text-normal-body {
    color: $cuColorGray1 !important;
  }
}

.link-warning-hover-body {
  border: 1px dashed transparent;
  &:hover {
    background-color: #ffc10726;
    border: 1px dashed $cuWarning1;
    border-radius: 6px;
  }
}

.common-divider {
  opacity: 0.5;
  color: $cuColorGray4;
}

.common-body-footer {
  line-height: 1.3;
  font-size: 12.5px;
  color: $cuColorGray1;
  a {
    text-decoration: none;
    color: inherit;
    font-size: 12.5px !important;
  }
}

.cu-navigate {
  display: flex;
  align-items: center;
  gap: 4px;

  .common-navigate-body {
    @include all_center();
    background-color: $cuColorGray4;
    width: 25px;
    height: 25px;
    padding: 2px 8px;
    border-radius: 6px;
  }
}

.common-tab-content {
  .nav {
    gap: 8px;
    padding: 12px 0;
    .nav-item {
      .nav-link {
        padding: 5px 10px;
        border-radius: 8px;
        background-color: $cuColorGray4;
        color: $cuColorBlack2;
        font-size: 12.5px;
        font-weight: 500;
        cursor: pointer;
        &.active {
          background-color: $primary;
          color: $white;
        }
        &:hover:not(.active) {
          background-color: $cuColorGray6;
        }
      }
    }
  }
  .tab-pane {
    .common-tab-body {
      a {
        text-decoration: none;
        color: inherit;
      }
      .block-divider {
        margin: 4px 0 20px 0;
      }
      .block-info-body {
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
      background-color: $white;
      border: 1px solid $cuColorGray4;
      border-radius: 12px;
      padding: 20px;
      .common-info-text {
        font-size: 14.5px;
        color: $cuColorBlack1;
        &.info-text-chip {
          border: 1px solid rgba(173, 181, 189, 0.25);
          background-color: $cuColorGray8;
          border-radius: 6px;
          font-size: 10px;
          color: $black;
          padding: 6px 8px;
          font-weight: 500;
          &.success-chip {
            color: $darkGreen;
            border-color: $darkGreen;
          }
          &.other-chip {
            background-color: $cuColorGray8;
            font-size: 10.5px;
            line-height: 1;
            .chip-secondary-text {
              color: $cuColorGray1;
              font-size: 10.5px;
            }
            .chip-info-text {
              color: $black;
              font-size: 10.5px;
            }
          }
        }
      }

      .common-secondary-text {
        color: $cuColorGray1;
        font-size: 14.5px;
      }
      .common-danger-text {
        color: $cuDanger1;
      }
      .common-success-text {
        color: $darkGreen;
      }
    }
  }
}

.custom-adv-dropdown {
  .cu-adv-dropdown-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: $primary;
    font-size: 12.5px;
    color: $white;
    padding: 4px 8px;
    border-radius: 6px;
    border: 1px solid $primary;
  }
  .dropdown-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    margin-top: 8px;
    border: none;
    position: relative;
    .dropdown-item:focus,
    .dropdown-item:hover {
      background-color: unset !important;
    }
    .sponsored-text {
      color: $cuColorGray1;
      font-size: 10.5px;
      position: absolute;
      top: 6px;
      right: 14px;
    }
  }
}

.sponsored-individual-card {
  width: 290px;
  @media screen and (max-width: 768px) {
    width: 260px;
  }
  .title {
    font-size: 14.5px;
    color: $black;
  }
  .link-body {
    line-height: 18.5px;

    .sub-header {
      color: $primary;
      margin-bottom: 8px;
      font-size: 12.5px;
      font-weight: 500;
      text-wrap: wrap;
    }
    .inner-chip {
      font-size: 9.5px;
      padding: 2px 7px;
      color: $primary;
      background-color: $cuprimary1;
      border-color: rgba(7, 132, 195, 0.25);
      border-radius: 50rem;
      width: fit-content;
    }
  }
  .desc-text {
    font-size: 12.5px;
    color: $black;
    text-wrap: wrap;
    line-height: 18.5px;
  }
}

.transaction-menu {
  .transaction-menu-button {
    padding: 4px 8px;
    border-radius: 6px;
    background-color: $white;
    border: 1px solid $cuColorGray4;
    &.cu-bg-secondary {
      background-color: $cuColorGray4;
    }
  }
  .main-menu-body {
    padding: 8px;
    .menu-item {
      color: $cuColorBlack1;
      padding: 6px 12px;
      font-size: 12.5px;
      &:hover {
        background-color: $cuColorGray4;
        border-radius: 6px;
      }
    }
  }
}
.custom-dropdown-menu {
  .custom-dropdown-menu-button {
    padding: 4px 8px;
    border-radius: 6px;
    background-color: $white;
    border: 1px solid $cuColorGray4;
  }
  &.cu-dropdown-white {
    .custom-dropdown-menu-button {
      background-color: $white;
      border: 1px solid $cuColorGray4;
      color: $cuColorBlack1;
      font-size: 12.5px;
      &:hover {
        background-color: $cuColorGray4;
      }
      &:active {
        border: 1px solid $cuColorGray4;
      }
    }
  }
  .main-menu-body {
    padding: 8px;
    .menu-item {
      color: $cuColorBlack1;
      padding: 6px 12px;
      font-size: 12.5px;
      &:hover {
        background-color: $cuColorGray4;
        border-radius: 6px;
      }
    }
  }
}

.cu-line-height-1 {
  line-height: 1;
}

.common-card-no-data-body {
  text-align: center;
  font-size: 14.5px;
  color: $cuColorBlack1;
}
